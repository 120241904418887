import type {CarouselItemData} from '@Components/carousel/carousel.types';
import type {GridItemStorage} from '@Components/base-grid/components/grid-item';
import {GRID_ITEM_TYPE} from '@Components/base-grid/components/grid-item';
import {isUserPremium} from '@Libraries/user.library';
import type {AspectRatio, StabilityAIStylePreset} from '@Libraries/pmw-stock-media-library';
import type {GridSkeletonItemStorage} from '@Components/base-grid/components/grid-skeleton-item';

export const IS_GETTY_ENABLED = true;

export enum StockPanelTabs {
  ALL = 'ALL',
  PHOTOS = 'PHOTOS',
  SHAPES = 'SHAPES',
  STICKERS = 'STICKERS',
  ICONS = 'ICONS',
  VIDEOS = 'VIDEOS',
  AUDIOS = 'AUDIO',
  TEXT_TO_IMAGE = 'TEXT_TO_IMAGE',
}

export const STOCK_PANEL_TAB_ID = 'STOCK_PANEL_TAB';

const NUMBER_OF_IMAGES_TO_GENERATE = 2;
export const AI_IMAGE_LOADING_SKELETONS: Array<GridSkeletonItemStorage> = Array.from({length: NUMBER_OF_IMAGES_TO_GENERATE}, (_, index): GridSkeletonItemStorage => {
  return {
    id: `AI_IMAGE_LOADER_${index + 1}`,
    type: GRID_ITEM_TYPE.SKELETON,
    width: 180,
    height: 70,
    useFixedDimensions: false,
  };
});

export const MIN_STOCK_ITEMS_TO_DISPLAY = 9;

export enum StockButtonFilters {
  PRICING = 'Pricing',
  ANIMATION = 'Animation',
  QUALITY = 'Quality',
  GENRE = 'Genre',
  INSTRUMENT = 'Instrument',
  MOOD = 'Mood',
  SFX = 'Effects',
  TYPE = 'Type',
  CATEGORY = 'Category',
}

export const ICON_FILTERS_TO_CATEGORY_MAPPING: Record<string, Array<string>> = {
  colored: ['lineal', 'colored_fill'],
  fill: ['black_and_white_fill', 'colored_fill'],
  outline: ['outline'],
  hand_drawn: ['hand_drawn'],
};

export enum StockGridIDs {
  ALL = 'all-items-grid',
  PHOTOS = 'photo-items-grid',
  VIDEOS = 'video-items-grid',
  AUDIOS = 'audio-items-grid',
  SHAPES = 'shapes-items-grid',
  ICONS = 'icons-items-grid',
  STICKERS = 'stickers-items-grid',
  TEXT_TO_IMAGE = 'text-to-image-stock-grid',
}

export enum StockCarouselIDs {
  RECENTS = 'recents-carousel',
  PHOTOS = 'photos-carousel',
  STICKERS = 'stickers-carousel',
  SHAPES = 'shapes-carousel',
  VIDEOS = 'videos-carousel',
  AUDIOS = 'audios-carousel',
  ICONS = 'icons-carousel',
  TEXT_TO_IMAGE = 'text-to-image-carousel',
}

export enum InputFieldIconID {
  CANCEL = 'CANCEL',
  SEARCH = 'SEARCH',
}

export const AUDIO_CATEGORY_ID_TO_STOCK_BUTTON_FILTER: Record<string, StockButtonFilters> = {
  genre: StockButtonFilters.GENRE,
  instrument: StockButtonFilters.INSTRUMENT,
  mood: StockButtonFilters.MOOD,
  sfx: StockButtonFilters.SFX,
};

export enum StockCarouselType {
  RECENTS = 'Recents',
  PHOTOS = 'Photos',
  STICKERS = 'Stickers',
  SHAPES = 'Shapes',
  VIDEOS = 'Videos',
  AUDIOS = 'Audio',
  ICONS = 'Icons',
  TEXT_TO_IMAGE = 'TEXT_TO_IMAGE',
}

export const STOCK_TABS_TO_CAROUSEL_TYPE_MAPPING: Record<StockPanelTabs, Array<StockCarouselType> | undefined> = {
  [StockPanelTabs.ALL]: [StockCarouselType.RECENTS],
  [StockPanelTabs.PHOTOS]: [StockCarouselType.PHOTOS],
  [StockPanelTabs.SHAPES]: [StockCarouselType.SHAPES],
  [StockPanelTabs.STICKERS]: [StockCarouselType.STICKERS],
  [StockPanelTabs.ICONS]: [StockCarouselType.ICONS],
  [StockPanelTabs.VIDEOS]: [StockCarouselType.VIDEOS],
  [StockPanelTabs.AUDIOS]: [StockCarouselType.AUDIOS],
  [StockPanelTabs.TEXT_TO_IMAGE]: [StockCarouselType.TEXT_TO_IMAGE],
};

export const AudioFilters = [StockButtonFilters.GENRE, StockButtonFilters.INSTRUMENT, StockButtonFilters.MOOD, StockButtonFilters.SFX];

export interface CarouselData {
  [StockCarouselType.RECENTS]?: Array<CarouselItemData>;
  [StockCarouselType.PHOTOS]?: Array<CarouselItemData>;
  [StockCarouselType.STICKERS]?: Array<CarouselItemData>;
  [StockCarouselType.SHAPES]?: Array<CarouselItemData>;
  [StockCarouselType.VIDEOS]?: Array<CarouselItemData>;
  [StockCarouselType.AUDIOS]?: Array<CarouselItemData>;
  [StockCarouselType.ICONS]?: Array<CarouselItemData>;
  [StockCarouselType.TEXT_TO_IMAGE]?: Array<CarouselItemData>;
}

export type StockFilterID = StockButtonFilters;

export type StockPrimaryCTA = (selectedItems: Array<GridItemStorage>) => Promise<void>;

// CodeReviewUsamaAsk: Whats the difference between StockFilterID/StockButtonFilters. Why is StockFilterID used twice here |
// if, in future these filters are data driven, we'd need an id and a name. For now, i've set the both to IDs
export interface StockFilter {
  id: StockFilterID;
  type: StockFilterTypes;
  name: string;
  icon?: StockFilterIcons;
  subFilters: Array<StockButtonSubfilter>;
}

export type StockSubfilter = StockButtonSubfilter;

export interface StockButtonSubfilter {
  text: string;
  id: string;
}

export type AIImageFiltersID = StabilityAIStylePreset | AspectRatio;

export interface AIImageFilters {
  id: AIImageFiltersID;
  text: string;
  thumbnailImage: string;
}

export interface SelectedFilter {
  filter: string;
  // CodeReviewUsama: Use undefined rather than null. Avoid null in typescript
  subFilter: string | null;
}

export enum StockFilterIcons {
  PRICING = 'icon-coins',
  STOCK_SOURCE = 'icon-stock-source',
  ANIMATION = 'icon-animation',
  QUALITY = 'icon-video',
  GENRE = 'icon-genre',
  INSTRUMENT = 'icon-guitar',
  MOOD = 'icon-sentiment-very-satisfied',
  SFX = 'icon-sfx',
  ELEMENTS = 'icon-elements',
}

export enum StockFilterTypes {
  BUTTON,
  DROPDOWN,
}

export const AUDIO_FILTER_ID_TO_ICON_MAPPING: Record<string, StockFilterIcons> = {
  genre: StockFilterIcons.GENRE,
  instrument: StockFilterIcons.INSTRUMENT,
  mood: StockFilterIcons.MOOD,
  sfx: StockFilterIcons.SFX,
};

export const QUALITY_SUBFILTERS: Array<StockButtonSubfilter> = [
  {
    text: '4K',
    id: '5',
  },
];

export const MOOD_SUBFILTERS: Array<StockButtonSubfilter> = [
  {
    text: 'Angry',
    id: '1',
  },
  {
    text: 'Serious',
    id: '2',
  },
  {
    text: 'Playful',
    id: '3',
  },
  {
    text: 'Inspiring',
    id: '4',
  },
  {
    text: 'Sad',
    id: '5',
  },
  {
    text: 'Relaxing',
    id: '6',
  },
  {
    text: 'Happy',
    id: '7',
  },
  {
    text: 'Love',
    id: '8',
  },
  {
    text: 'Epic',
    id: '124',
  },
  {
    text: 'Dark',
    id: '125',
  },
];

export const SFX_SUBFILTERS: Array<StockSubfilter> = [
  {
    text: 'Alarms',
    id: '12',
  },
  {
    text: 'Bells',
    id: '13',
  },
];

export const GENRE_SUBFILTERS: Array<StockButtonSubfilter> = [
  {
    text: 'Rock',
    id: '9',
  },
  {
    text: 'Chill Out',
    id: '10',
  },
  {
    text: 'Cinematic',
    id: '11',
  },
  {
    text: 'Country',
    id: '13',
  },
  {
    text: 'Pop',
    id: '15',
  },
  {
    text: 'Hip Hop',
    id: '20',
  },
  {
    text: 'World',
    id: '21',
  },
  {
    text: 'Corporate',
    id: '22',
  },
  {
    text: 'Holidays & Special Events',
    id: '25',
  },
  {
    text: 'Horror',
    id: '26',
  },
  {
    text: 'Jazz',
    id: '29',
  },
  {
    text: 'Kids & Family',
    id: '31',
  },
  {
    text: 'Classical',
    id: '32',
  },
  {
    text: 'Ambient',
    id: '33',
  },
  {
    text: 'Religious',
    id: '34',
  },
  {
    text: 'Blues',
    id: '36',
  },
  {
    text: 'Electronic',
    id: '37',
  },
  {
    text: 'Bumpers & Stingers',
    id: '38',
  },
  {
    text: 'R&B',
    id: '116',
  },
  {
    text: 'Folk',
    id: '18',
  },
];

export const INSTRUMENT_SUBFLITER: Array<StockButtonSubfilter> = [
  {
    text: 'Orchestra',
    id: '39',
  },
  {
    text: 'Guitar',
    id: '40',
  },
  {
    text: 'Brass',
    id: '41',
  },
  {
    text: 'Percussion',
    id: '42',
  },
  {
    text: 'Vocals',
    id: '43',
  },
  {
    text: 'Woodwind',
    id: '44',
  },
  {
    text: 'Piano',
    id: '45',
  },
  {
    text: 'Synth',
    id: '119',
  },
];

export const DEFAULT_APPLIED_SUBFILTER: StockSubfilter = {
  text: 'All',
  id: '0',
};

export const UHD_APPLIED_VIDEO_FILTER_ID = '5';

export const ELEMENTS_SUBFILTERS: Array<StockButtonSubfilter> = [
  {
    text: 'Stickers',
    id: '1',
  },
  {
    text: 'Shapes',
    id: '2',
  },
  {
    text: 'Icons',
    id: '3',
  },
];

export const PRICING_SUBFILTER_FREE = '1';

export const getPricingSubFilter = (): Array<StockButtonSubfilter> => {
  return [
    {
      text: window.i18next.t('pmwjs_free'),
      id: PRICING_SUBFILTER_FREE,
    },
    {
      text: isUserPremium() ? window.i18next.t('pmwjs_getty_images') : window.i18next.t('pmwjs_paid'),
      id: '2',
    },
  ];
};

export const getPricingFilter = (): StockFilter => {
  const pricingFilterNmae = isUserPremium() ? window.i18next.t('pmwjs_stock_source') : window.i18next.t('pmwjs_pricing');
  const pricingFilterIcon = isUserPremium() ? StockFilterIcons.STOCK_SOURCE : StockFilterIcons.PRICING;
  return {type: StockFilterTypes.BUTTON, name: pricingFilterNmae, icon: pricingFilterIcon, subFilters: getPricingSubFilter(), id: StockButtonFilters.PRICING};
};

export const getAnimationSubFilters = (): Array<StockButtonSubfilter> => {
  return [
    {
      text: window.i18next.t('pmwjs_animated'),
      id: '3',
    },
    {
      text: window.i18next.t('pmwjs_still'),
      id: '4',
    },
  ];
};
export const getIconSubFilters = (): Array<StockButtonSubfilter> => {
  return [
    {
      text: window.i18next.t('pmwjs_colored'),
      id: 'colored',
    },
    {
      text: window.i18next.t('pmwjs_fill'),
      id: 'fill',
    },
    {
      text: window.i18next.t('pmwjs_outline'),
      id: 'outline',
    },
    {
      text: window.i18next.t('pmwjs_hand_drawn'),
      id: 'hand_drawn',
    },
  ];
};

export const getStockTabsToFilterMapping = (videoQualityFiltersDisabled?: boolean): Record<StockPanelTabs, Array<StockFilter>> => {
  return {
    [StockPanelTabs.ALL]: [
      ...(IS_GETTY_ENABLED ? [getPricingFilter()] : []),
      {
        type: StockFilterTypes.BUTTON,
        name: window.i18next.t('pmwjs_animation'),
        icon: StockFilterIcons.ANIMATION,
        subFilters: getAnimationSubFilters(),
        id: StockButtonFilters.ANIMATION,
      },
    ],
    [StockPanelTabs.PHOTOS]: [...(IS_GETTY_ENABLED ? [getPricingFilter()] : [])],
    [StockPanelTabs.SHAPES]: [],
    [StockPanelTabs.STICKERS]: [
      ...(IS_GETTY_ENABLED ? [getPricingFilter()] : []),
      {type: StockFilterTypes.BUTTON, name: StockButtonFilters.ANIMATION, icon: StockFilterIcons.ANIMATION, subFilters: getAnimationSubFilters(), id: StockButtonFilters.ANIMATION},
    ],
    [StockPanelTabs.ICONS]: [{type: StockFilterTypes.BUTTON, name: StockButtonFilters.CATEGORY, subFilters: getIconSubFilters(), id: StockButtonFilters.CATEGORY}],
    [StockPanelTabs.VIDEOS]: [
      ...(IS_GETTY_ENABLED ? [getPricingFilter()] : []),
      {
        type: StockFilterTypes.BUTTON,
        name: window.i18next.t('pmwjs_quality'),
        icon: StockFilterIcons.QUALITY,
        subFilters: videoQualityFiltersDisabled ? [] : QUALITY_SUBFILTERS,
        id: StockButtonFilters.QUALITY,
      },
    ],
    [StockPanelTabs.AUDIOS]: [
      {type: StockFilterTypes.DROPDOWN, name: window.i18next.t('pmwjs_mood'), icon: StockFilterIcons.MOOD, subFilters: MOOD_SUBFILTERS, id: StockButtonFilters.MOOD},
      {type: StockFilterTypes.DROPDOWN, name: window.i18next.t('pmwjs_sfx'), icon: StockFilterIcons.SFX, subFilters: SFX_SUBFILTERS, id: StockButtonFilters.SFX},
      {type: StockFilterTypes.DROPDOWN, name: window.i18next.t('pmwjs_genre'), icon: StockFilterIcons.GENRE, subFilters: GENRE_SUBFILTERS, id: StockButtonFilters.GENRE},
      {
        type: StockFilterTypes.DROPDOWN,
        name: window.i18next.t('pmwjs_intrument'),
        icon: StockFilterIcons.INSTRUMENT,
        subFilters: INSTRUMENT_SUBFLITER,
        id: StockButtonFilters.INSTRUMENT,
      },
    ],
    [StockPanelTabs.TEXT_TO_IMAGE]: [],
  };
};

export const STOCK_SUBFILTER_TO_ADDITIONAL_FILTERS_MAP: Record<StockPanelTabs, Record<string, Record<string, Array<StockFilter>>>> = {
  [StockPanelTabs.ALL]: {},
  [StockPanelTabs.PHOTOS]: {},
  [StockPanelTabs.SHAPES]: {},
  [StockPanelTabs.STICKERS]: {},
  [StockPanelTabs.ICONS]: {},
  [StockPanelTabs.VIDEOS]: {},
  [StockPanelTabs.AUDIOS]: {},
  [StockPanelTabs.TEXT_TO_IMAGE]: {},
};

export const AUDIO_CATEGORIES_REQUEST_LOCAL_STORAGE_CACHE_KEY = 'AudioCategoriesRequestCacheKey';
export const DISABLE_PIXABY_SOURCES = true;

export interface GridItemBaseStorageBackendData {
  [key: string]: any;
}
